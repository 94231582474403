<nav class="navbar fixed-top" *ngIf="isVisible | async">
  <div class="d-flex align-items-center navbar-left">
    <a
      (click)="menuButtonClick($event, sidebar.menuClickCount, sidebar.containerClassnames); (false)"
      href="javascript:;"
      class="menu-button d-none d-md-block"
    >
      <svg class="main side-menu-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 9 17">
        <rect x="0.48" y="0.5" width="7" height="1" />
        <rect x="0.48" y="7.5" width="7" height="1" />
        <rect x="0.48" y="15.5" width="7" height="1" />
      </svg>
      {{ ' ' }}
      <svg class="sub side-menu-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 17">
        <rect x="1.56" y="0.5" width="16" height="1" />
        <rect x="1.56" y="7.5" width="16" height="1" />
        <rect x="1.56" y="15.5" width="16" height="1" />
      </svg>
    </a>

    <a [routerLink]="['/']" class="commission-logo">
      <img class="logo-img-sm" src="/assets/images/cw-logo-without-text.png" alt="Request for Service Tool - Cushman & Wakefield" />
      <label class="logo-text">Request for Service Tool</label>
    </a>

    <a
      (click)="mobileMenuButtonClick($event, sidebar.containerClassnames)"
      href="javascript:;"
      class="menu-button-mobile d-xs-block d-sm-block d-md-none"
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 17">
        <rect x="0.5" y="0.5" width="25" height="1" />
        <rect x="0.5" y="7.5" width="25" height="1" />
        <rect x="0.5" y="15.5" width="25" height="1" />
      </svg>
    </a>
    <div class="bread-crumb-outer">
      <div class="gap"></div>
      <div class="bread-crumb navbar-left">
        <label>
          <div class="line"></div>
          <span class="breadCrumbTitle">{{ breadCrumbTitle }}</span></label
        >
      </div>
    </div>
  </div>

  <div class="navbar-right">
    <div class="user d-inline-block" dropdown>
      <ng-template #popTemplate
        ><div>{{ currentUser?.name }}</div>
        <div>{{ currentUser?.email }}</div>
      </ng-template>
      <button
        *ngIf="currentUser?.isAuthorized"
        class="btn btn-empty p-0"
        type="button"
        dropdownToggle
        placement="left"
        [tooltip]="popTemplate"
        [delay]="1000"
      >
        <span>
          <div class="power-user" *ngIf="isAdministrator">
            <em class="fas fa-rocket-launch"></em>
          </div>
          <img class="user-img" alt="" [src]="'https://graph.microsoft.com/v1.0/me/photo/$value' | secureImage | async"
        /></span>
      </button>
      <ul class="dropdown-menu dropdown-menu-right mt-3" *dropdownMenu role="menu">
        <a href="javascript:;" class="dropdown-item" (click)="logout()" id="logout">Sign out</a>
      </ul>
    </div>
  </div>
</nav>
