import { Component, Input } from '@angular/core';
import { CONSTANTS } from '../../constants/project.constant';

@Component({
  selector: 'app-empty-data-card',
  templateUrl: './empty-data-card.component.html',
  styleUrls: ['./empty-data-card.component.scss']
})
export class EmptyDataCardComponent {
  @Input() data!: [];
  @Input() areFiltersActive = false;
  @Input() isSearchActive = false;
  @Input() isLoading = true;
  @Input() emptyDataMessage: string = CONSTANTS.emptyDataMessage;

  get message(): string {
    return this.isLoading ? CONSTANTS.dataLoadingMessage : this.getDataEmptyMessage();
  }

  private getDataEmptyMessage(): string {
    return this.areFiltersActive ? CONSTANTS.emptyFilteredDataMessage : this.getOtherMessage();
  }

  private getOtherMessage(): string {
    return this.isSearchActive ? CONSTANTS.emptySearchResultsMessage : this.emptyDataMessage;
  }
}
