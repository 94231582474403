<div class="row">
  <div class="col-sm-1"><h1 class="visibility-hidden">Links</h1></div>
  <div class="col-sm-11 home-main-dropdowns">
    <button type="button" class="btn btn-primary float-right" id="btnCreateGroup" *ngIf="isActionsVisible" (click)="addNewLink()">
      <i class="fa fa-plus mr-1" aria-hidden="true"></i>
      Create Link
    </button>
    <app-client-selector (onSelectionChange)="onChangeClient($event)"></app-client-selector>
  </div>
</div>

<div class="row">
  <div class="col">
    <app-empty-data-card [data]="dataList" [isLoading]="isLoading" *ngIf="!searchText"></app-empty-data-card>
    <div class="card d-flex flex-row mb-3" searchText *ngIf="dataList.length === 0 && searchText">
      <div class="card-body">
        <p class="mb-0">{{ noSearchResults }}</p>
      </div>
    </div>
    <div class="card mb-3" *ngFor="let data of dataList; let i = index">
      <div class="d-flex flex-grow-1 min-width-zero">
        <div class="card-body align-self-center d-flex flex-column flex-md-row justify-content-between min-width-zero align-items-md-center">
          <a title="Open Link" href="{{ data.link }}" target="_blank">
            <span class="color-theme-3 w-30 w-xs-100"> Title : {{ data.title }} <i class="fal fa-link c-pointer float-right ml-2 mt-1"></i></span>
          </a>
          <p class="mb-0 text-muted w-15 w-xs-100">
            <a title="Edit Link" *ngIf="isActionsVisible" (click)="onEditData(data)"><i class="fal fa-pencil-alt c-pointer float-right mr-3"></i></a>
            <a title="Remove Link" *ngIf="isActionsVisible" (click)="onRemoveData(data)"
              ><i class="fal fa-trash-alt c-pointer float-right mr-3"></i
            ></a>
          </p>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template id="createFormRef" #template>
  <app-client-link-create
    [selectedData]="selectedData"
    (onCreateEvent)="onCreate()"
    (onCreateCancel)="onCreateCancel($event)"
  ></app-client-link-create>
</ng-template>

<app-confirm #confirmFormRef (confirmed)="confirmed($event)"></app-confirm>
