export class PaginatedListItem<T> {
  items!: T[];
  pageNumber!: number;
  totalPages!: number;
  totalCount!: number;

  constructor(source: Partial<PaginatedListItem<T>>) {
    Object.assign(this, source);
  }
}
