<ng-template #aboutTemplate>
  <div class="about-info-wrapper">
    <div class="modal-header">
      <h1>Request for Service Tool</h1>
      <button type="button" class="close" aria-label="Close" (click)="onCancel()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>{{ version }}</p>
      <p>Copyright © CW {{ currentYear }}</p>
    </div>
  </div>
</ng-template>
