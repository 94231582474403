<div class="row">
  <div class="col">
    <div class="top-right-button-container mb-2">
      {{ (currentPage - 1) * pageSize + 1 }} - {{ pageSize * currentPage > totalCount ? totalCount : pageSize * currentPage }} of
      {{ totalCount }}
      <div class="btn-group" dropdown>
        <button
          id="pagesize-button"
          type="button"
          dropdownToggle
          aria-controls="pagesize-dropdown"
          class="btn btn-outline-dark btn-xs dropdown-toggle"
        >
          {{ pageSize }}
          <span class="caret"></span>
        </button>
        <ul id="pagesize-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="pagesize-button">
          <li role="menuitem">
            <a href="javascript:;" class="dropdown-item" (click)="onPageSizeChange(5)">5</a>
          </li>
          <li role="menuitem">
            <a href="javascript:;" class="dropdown-item" (click)="onPageSizeChange(10)">10</a>
          </li>
          <li role="menuitem">
            <a href="javascript:;" class="dropdown-item" (click)="onPageSizeChange(20)">20</a>
          </li>
          <li role="menuitem">
            <a href="javascript:;" class="dropdown-item" (click)="onPageSizeChange(50)">50</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
