/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AdUser, User } from '../../models/user.model';
import { StorageService } from '../../services/local-storage.service';

import { MenuService } from '../../services/menu.service';
import { ISidebar, SidebarService } from '../../services/sidebar.service';
import { UserService } from '../../services/user.service';

import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-top-nav',
  templateUrl: './top-nav.component.html',

  styleUrls: ['./top-nav.component.scss']
})
export class TopNavComponent extends BaseComponent implements OnInit {
  currentAdUser: AdUser | null = null;
  currentUser!: User;
  isLoggedIn = false;
  isAdministrator = false;
  breadCrumbTitle?: string;

  appConfig: any;
  sidebar: ISidebar | any;
  isVisible: Observable<boolean>;

  get userDetails(): string {
    return `<div>${this.currentUser?.name}</div><div>${this.currentUser?.email}    
    </div><div class="divider dropdown-divider"></div><div>
    ${this.currentUser.roleClients.find((x) => x.clientGuid === this.storageService.lastClientSelectorData.clientGuid)?.roleName}
    </div>`;
  }

  constructor(
    private readonly sidebarService: SidebarService,
    private readonly userService: UserService,
    private readonly menuService: MenuService,
    private readonly storageService: StorageService
  ) {
    super();

    this.isVisible = this.menuService.data$;
  }

  ngOnInit(): void {
    this.userService.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user: User) => {
      this.currentUser = user;
      this.isAdministrator =
        this.currentUser.roleClients.find((x) => x.clientGuid === this.storageService.lastClientSelectorData.clientGuid)?.roleName ===
        'Administrator';
    });

    this.userService.isLoggedIn$.pipe(takeUntil(this.destroyed$)).subscribe((isLoggedIn) => {
      this.isLoggedIn = isLoggedIn;
    });

    this.userService.currentAdUser$.pipe(takeUntil(this.destroyed$)).subscribe((adUser) => {
      this.currentAdUser = adUser;
    });

    this.sidebarService
      .getSidebar()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.sidebar = res;
          this.getBreadCrumb();
        },
        (err) => {
          console.error(`An error occurred: ${err.message}`);
        }
      );
  }

  menuButtonClick = (e: { stopPropagation: () => void }, menuClickCount: number | 0, containerClassnames: string) => {
    if (e) {
      e.stopPropagation();
    }

    setTimeout(() => {
      const event = document.createEvent('HTMLEvents');
      event.initEvent('resize', false, false);
      window.dispatchEvent(event);
    }, 350);

    this.sidebarService.setContainerClassnames(menuClickCount + 1, containerClassnames, this.sidebar.selectedMenuHasSubItems);
  };

  mobileMenuButtonClick = (event: { stopPropagation: () => void }, containerClassnames: string) => {
    if (event) {
      event.stopPropagation();
    }
    this.sidebarService.clickOnMobileMenu(containerClassnames);
  };

  getBreadCrumb() {
    this.menuService.breadCrumbTitle.pipe(takeUntil(this.destroyed$)).subscribe(
      (res: string) => {
        this.breadCrumbTitle = res;
      },
      () => {
        this.breadCrumbTitle = 'Home';
      }
    );
  }

  logout(): void {
    this.userService.logout();
  }

  login(): void {
    this.userService.login();
  }
}
