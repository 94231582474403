/* eslint-disable indent */
/* eslint-disable @typescript-eslint/naming-convention */
import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { RequestStatus, RequestStatusTypes } from '../constants/request-status.constants';

const enum RequestStatusType {
  Draft = 'draft',
  Submitted = 'submitted',
  Awaiting = 'awaiting',
  Approved = 'approved',
  Declined = 'rejected',
  Aborted = 'aborted',
  Completed = 'completed'
}

@Directive({
  selector: '[requestStatusBadge]'
})
export class RequestStatusColorDirective implements OnInit {
  @Input() modelStatus!: RequestStatus;

  constructor(private readonly elementRef: ElementRef, private readonly renderer: Renderer2) {}

  ngOnInit(): void {
    this.setAccentClass();
  }

  private setAccentClass(): void {
    let accentCss: RequestStatusType = RequestStatusType.Draft;

    switch (this.modelStatus) {
      case RequestStatusTypes.SubmittedForReview:
        accentCss = RequestStatusType.Submitted;
        break;
      case RequestStatusTypes.AwaitingRequesterApproval:
        accentCss = RequestStatusType.Awaiting;
        break;
      case RequestStatusTypes.Approved:
        accentCss = RequestStatusType.Approved;
        break;
      case RequestStatusTypes.Declined:
        accentCss = RequestStatusType.Declined;
        break;
      case RequestStatusTypes.Aborted:
        accentCss = RequestStatusType.Aborted;
        break;
      case RequestStatusTypes.Completed:
        accentCss = RequestStatusType.Completed;
        break;

      default:
        break;
    }

    this.renderer.addClass(this.elementRef.nativeElement, accentCss);
  }
}
