export const environment = {
  production: true,
  isAuthGuardActive: true,
  azureApplicationId: 'ecb46226-2c81-45f2-83bf-a2604e0cd8de',
  azureApiScopeUri: 'api://ecb46226-2c81-45f2-83bf-a2604e0cd8de/api.access',
  azureDirectoryId: '46c5178e-a0f4-4f4d-8c40-9598e3d11860',
  appInsightsKey: '95b79ebe-af59-4dae-9798-4b811a29c4e5',
  azureRedirectUrl: 'https://cw-emea-comtool-rg-dev-webapp.azurewebsites.net',
  graphApiUrl: 'https://graph.microsoft.com/v1.0',
  version: 'DEV Azure',
  isMockData: false,
  defaultMenuType: 'menu-default',
  subHiddenBreakpoint: 1440,
  menuHiddenBreakpoint: 768,
  azureDataFunctionUrl: 'https://cw-emea-comtool-d-api.azurewebsites.net'
};
