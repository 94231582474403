import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MenuService {
  public breadCrumbTitle: BehaviorSubject<string> = new BehaviorSubject<string>('Home');
  public searchIconVisibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  //breadcrumb
  constructor() {
    this.getBreadCrumbTitle();
  }

  setBreadCrumbTitle(title: string): void {
    if (title) {
      localStorage.setItem('breadCrumb', title);
      this.breadCrumbTitle.next(title);
    }
  }

  getBreadCrumbTitle(): Observable<string> {
    return new Observable(() => {
      const breadCrumbTitle = localStorage.getItem('breadCrumb');
      if (breadCrumbTitle == null || breadCrumbTitle == '') {
        this.breadCrumbTitle.next('Home');
      } else {
        this.breadCrumbTitle.next(breadCrumbTitle);
      }
    });
  }

  //show/hide topnav and side bar
  private readonly areMenusVisible$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  private areMenusVisible = true;
  data$ = this.areMenusVisible$.asObservable();

  showTopNavAndSideMenu(): void {
    this.areMenusVisible = true;
    this.emitNext();
  }

  hideTopNavAndSideMenu(): void {
    this.areMenusVisible = false;
    this.emitNext();
  }

  private emitNext(): void {
    this.areMenusVisible$.next(this.areMenusVisible);
  }
}
