import { Directive, ElementRef, HostListener } from '@angular/core';
@Directive({
  selector: '[preventKeyUp]'
})
export class PreventKeyUpDirective {
  constructor(private el: ElementRef) {}
  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    event.preventDefault();
  }
}
