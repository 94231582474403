/* eslint-disable rxjs-angular/prefer-takeuntil */
/* eslint-disable no-loops/no-loops */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { interval, Observable } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { ISidebar, SidebarService } from 'src/app/shared/services/sidebar.service';

import { MenuTypes } from '../../constants/menu-type';
import menuItems, { IMenuItem } from '../../constants/menu.constant';
import { UserRole } from '../../constants/roles.constant';
import { User } from '../../models/user.model';
import { StorageService } from '../../services/local-storage.service';
import { MenuService } from '../../services/menu.service';

import { UserService } from '../../services/user.service';
import { BaseComponent } from '../base.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent extends BaseComponent implements OnInit {
  menuItems: IMenuItem[] = menuItems;
  selectedParentMenu = '';
  viewingParentMenu = '';
  currentUrl = '';
  sidebar: ISidebar | any;
  closedCollapseList = [];
  isEnabled = false;
  isVisible: Observable<boolean>;
  private userRole: UserRole = 'Unknown';
  constructor(
    private router: Router,
    private sidebarService: SidebarService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private readonly menuService: MenuService,
    private readonly storageService: StorageService
  ) {
    super();
    this.isVisible = this.menuService.data$;

    this.sidebarService
      .getSidebar()
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        (res) => {
          this.sidebar = res;
        },
        (err) => {
          console.error(`An error occurred: ${err.message}`);
        }
      );

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        takeUntil(this.destroyed$)
      )
      .subscribe((event) => {
        const path = this.router.url.split('?')[0];
        const paramtersLen = Object.keys(event.snapshot.params).length;
        const pathArr = path.split('/').slice(0, path.split('/').length - paramtersLen);
        this.currentUrl = pathArr.join('/');
      });

    router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$)
      )
      .subscribe(() => {
        const { containerClassnames } = this.sidebar;
        this.selectMenu();
        this.sidebarService.setContainerClassnames(0, containerClassnames, this.sidebar.selectedMenuHasSubItems);
        window.scrollTo(0, 0);
      });
  }

  ngOnInit(): void {
    this.userService.currentUser$.pipe(takeUntil(this.destroyed$)).subscribe((user: User) => {
      this.isEnabled = user.isAuthorized();
      this.userRole = user.roleClients.find((x) => x.clientGuid === this.storageService.lastClientSelectorData.clientGuid)?.roleName as UserRole;
    });

    interval(100)
      .pipe(take(1))
      .subscribe(() => {
        this.selectMenu();
        const { containerClassnames } = this.sidebar;
        const nextClasses = this.getMenuClassesForResize(containerClassnames);
        this.sidebarService.setContainerClassnames(0, nextClasses.join(' '), this.sidebar.selectedMenuHasSubItems);
        this.isCurrentMenuHasSubItem();
      });
  }

  selectMenu(): void {
    this.selectedParentMenu = this.findParentInPath(this.currentUrl) || '';
    this.isCurrentMenuHasSubItem();
  }

  findParentInPath(path: string): string {
    const foundedMenuItem = this.menuItems.find((x) => x.to === path);
    if (!foundedMenuItem) {
      if (path && path.split('/').length > 1) {
        const pathArr = path.split('/');
        return this.findParentInPath(pathArr.slice(0, pathArr.length - 1).join('/'));
      } else {
        return '';
      }
    } else {
      return path;
    }
  }

  isCurrentMenuHasSubItem(): boolean {
    const { containerClassnames } = this.sidebar;

    const menuItem = this.menuItems.find((x) => x.to === this.selectedParentMenu);
    const isCurrentMenuHasSubItem = menuItem && menuItem.subs && menuItem.subs.length > 0 ? true : false;
    if (isCurrentMenuHasSubItem !== this.sidebar.selectedMenuHasSubItems) {
      if (!isCurrentMenuHasSubItem) {
        this.sidebarService.setContainerClassnames(0, containerClassnames, false);
      } else {
        this.sidebarService.setContainerClassnames(0, containerClassnames, true);
      }
    }
    return isCurrentMenuHasSubItem;
  }

  changeSelectedParentHasNoSubmenu(parentMenu: string): void {
    const { containerClassnames } = this.sidebar;
    this.selectedParentMenu = parentMenu;
    this.viewingParentMenu = parentMenu;
    this.sidebarService.changeSelectedMenuHasSubItems(false);
    this.sidebarService.setContainerClassnames(0, containerClassnames, false);
  }

  openSubMenu(event: { stopPropagation: () => void }, menuItem: IMenuItem): void {
    if (event) {
      event.stopPropagation();
    }
    const { containerClassnames, menuClickCount } = this.sidebar;

    const selectedParent = menuItem.to;
    const hasSubMenu = menuItem.subs && menuItem.subs.length > 0;
    this.sidebarService.changeSelectedMenuHasSubItems(hasSubMenu);
    if (hasSubMenu) {
      this.hasSubMenu(containerClassnames, menuClickCount, selectedParent);
    } else {
      this.hasNoSubMenu(selectedParent);
    }
  }

  private hasSubMenu(containerClassnames: any, menuClickCount: any, selectedParent: string) {
    const currentClasses = containerClassnames ? containerClassnames.split(' ').filter((x: any) => x !== '') : '';

    if (!currentClasses.includes(MenuTypes.MenuMobile)) {
      if (currentClasses.includes(MenuTypes.MenuSubHidden) && (menuClickCount === 2 || menuClickCount === 0)) {
        this.sidebarService.setContainerClassnames(3, containerClassnames, true);
      } else if (currentClasses.includes(MenuTypes.MenuHidden) && (menuClickCount === 1 || menuClickCount === 3)) {
        this.sidebarService.setContainerClassnames(2, containerClassnames, true);
      } else if (
        currentClasses.includes(MenuTypes.MenuDefault) &&
        !currentClasses.includes(MenuTypes.MenuSubHidden) &&
        (menuClickCount === 1 || menuClickCount === 3)
      ) {
        this.sidebarService.setContainerClassnames(0, containerClassnames, true);
      }
    } else {
      this.sidebarService.addContainerClassname('sub-show-temporary', containerClassnames);
    }
    this.viewingParentMenu = selectedParent;
  }

  private hasNoSubMenu(selectedParent: string) {
    this.viewingParentMenu = selectedParent;
    this.selectedParentMenu = selectedParent;
    this.toggle();
  }

  toggle(): void {
    const { containerClassnames, menuClickCount } = this.sidebar;
    const currentClasses = containerClassnames.split(' ').filter((x: string) => x !== '');
    if (currentClasses.includes(MenuTypes.MenuSubHidden) && menuClickCount === 3) {
      this.sidebarService.setContainerClassnames(2, containerClassnames, this.sidebar.selectedMenuHasSubItems);
    } else if (
      (currentClasses.includes(MenuTypes.MenuHidden) || currentClasses.includes(MenuTypes.MenuMobile)) &&
      !(menuClickCount === 1 && !this.sidebar.selectedMenuHasSubItems)
    ) {
      this.sidebarService.setContainerClassnames(0, containerClassnames, this.sidebar.selectedMenuHasSubItems);
    }
  }

  toggleCollapse(id: never): void {
    if (this.closedCollapseList.includes(id)) {
      this.closedCollapseList = this.closedCollapseList.filter((x) => x !== id);
    } else {
      this.closedCollapseList.push(id);
    }
  }

  getMenuClassesForResize(classes: string): string[] {
    let nextClasses = classes.split(' ').filter((x: string) => x !== '');
    const windowWidth = window.innerWidth;

    if (windowWidth < this.sidebarService.menuHiddenBreakpoint) {
      nextClasses.push(MenuTypes.MenuMobile);
    } else if (windowWidth < this.sidebarService.subHiddenBreakpoint) {
      nextClasses = nextClasses.filter((x: string) => x !== MenuTypes.MenuMobile);
      if (nextClasses.includes(MenuTypes.MenuDefault) && !nextClasses.includes(MenuTypes.MenuSubHidden)) {
        nextClasses.push(MenuTypes.MenuSubHidden);
      }
    } else {
      nextClasses = nextClasses.filter((x: string) => x !== MenuTypes.MenuMobile);
      if (nextClasses.includes(MenuTypes.MenuDefault) && nextClasses.includes(MenuTypes.MenuSubHidden)) {
        nextClasses = nextClasses.filter((x: string) => x !== MenuTypes.MenuSubHidden);
      }
    }
    return nextClasses;
  }

  @HostListener('document:click', ['$event'])
  handleDocumentClick(): void {
    this.viewingParentMenu = '';
    this.selectMenu();
    this.toggle();
  }

  menuClicked(e: MouseEvent): void {
    e.stopPropagation();
  }

  filteredMenuItems(items: IMenuItem[]): IMenuItem[] {
    return items ? items.filter((x) => !x.roles || (x.roles && x.roles.includes(this.userRole))) : [];
  }
}
