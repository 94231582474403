import { ErrorHandler, Injectable } from '@angular/core';
import { AppInsightsService } from './app-insights.service';

@Injectable()
export class ErrorHandlerService extends ErrorHandler {
  constructor(private readonly appInsightsService: AppInsightsService) {
    super();
  }

  handleError(error: Error): void {
    this.appInsightsService.logException(error);
  }
}
