<ng-template #confTemplate>
  <div class="modal-header">
    <h2 class="modal-title pull-left color-theme-1">{{ modalTitle }}</h2>
  </div>
  <div class="modal-body">
    <p>{{ modalContent }}</p>
    <textarea *ngIf="commentBox" name="reviewerComment" class="form-control mt-3" rows="3" required [(ngModel)]="comment"></textarea>
  </div>
  <div class="modal-footer drag-right">
    <button type="button" class="btn btn-sm btn-primary" [disabled]="isButtonsDisabled" (click)="onConfirm()">{{ okButtonText }}</button>
    <button type="button" class="btn btn-sm btn-outline-primary" (click)="onCancel()">
      {{ cancelButtonText }}
    </button>
  </div>
</ng-template>
