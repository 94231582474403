import { DatePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalModule, MsalRedirectComponent, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpRequestInterceptor } from './shared/interceptors/http-request-interceptor';
import { LoadingScreenInterceptor } from './shared/interceptors/loading.interceptor';
import { MockHttpRequestInterceptor } from './shared/interceptors/mock-http-request.interceptor';
import { AppInsightsService } from './shared/services/app-insights.service';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { SharedModule } from './shared/shared.module';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const interceptorProviders = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: environment.isMockData ? MockHttpRequestInterceptor : HttpRequestInterceptor,
    multi: true
  }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,

    FormsModule,
    SharedModule,
    BrowserModule,
    ToastrModule.forRoot({ preventDuplicates: true }),
    MsalModule.forRoot(
      new PublicClientApplication({
        // MSAL Configuration
        auth: {
          clientId: environment.azureApplicationId,
          authority: `https://login.microsoftonline.com/${environment.azureDirectoryId}`,
          redirectUri: environment.azureRedirectUrl,
          postLogoutRedirectUri: `${environment.azureRedirectUrl}/home/logout`
        },
        cache: {
          cacheLocation: BrowserCacheLocation.LocalStorage,
          storeAuthStateInCookie: isIE
        },
        system: {
          loggerOptions: {
            //loggerCallback: (logLevel, message) => console.log(message),
            logLevel: LogLevel.Error,
            piiLoggingEnabled: false
          }
        }
      }),
      {
        interactionType: InteractionType.Redirect
      },
      {
        interactionType: InteractionType.Redirect,
        protectedResourceMap: new Map([
          [`${environment.graphApiUrl}/me`, ['user.read']],
          [`${environment.graphApiUrl}/users`, ['user.readbasic.all']],
          [environment.azureDataFunctionUrl, [environment.azureApiScopeUri]]
        ])
      }
    )
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingScreenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: environment.isMockData ? MockHttpRequestInterceptor : HttpRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    DatePipe,
    AppInsightsService
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule {}
