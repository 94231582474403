import { Injectable } from '@angular/core';
import { PageName } from '../constants/page-name.constant';
import { CONSTANTS } from '../constants/project.constant';
import { ClientSelectorData } from '../models/client';
import { CommissionRequestFilterFormModel } from '../models/commission-request-filter-Params';
import { ItemsPerPage } from '../models/items-per-page';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  get lastClientSelectorData(): ClientSelectorData {
    return JSON.parse(localStorage.getItem('ClientDetails') || JSON.stringify(new ClientSelectorData()));
  }

  set lastClientSelectorData(data: ClientSelectorData) {
    localStorage.setItem('ClientDetails', JSON.stringify(data));
  }

  getFilterParams(uniqueId: string, fallbackFilter: CommissionRequestFilterFormModel): CommissionRequestFilterFormModel {
    return this.getFilterParamsMap()?.get(uniqueId) ?? fallbackFilter;
  }

  private getFilterParamsMap(): Map<string, CommissionRequestFilterFormModel> | undefined {
    if (!localStorage.getItem('FilterParamsMap')) {
      return undefined;
    }

    return new Map<string, CommissionRequestFilterFormModel>(JSON.parse(localStorage.getItem('FilterParamsMap')!));
  }

  setFilterParams(uniqueId: string, type: CommissionRequestFilterFormModel): void {
    const map = this.getFilterParamsMap() ?? new Map<string, CommissionRequestFilterFormModel>();
    map.set(uniqueId, type);
    localStorage.setItem('FilterParamsMap', JSON.stringify(Array.from(map.entries())));
  }

  getItemsPerPage(pageName: PageName): number {
    const itemsPerPage = this.getItemsPerPageObject(pageName);
    return itemsPerPage?.pageSize ?? CONSTANTS.pageSize;
  }

  setItemsPerPage(pageName: PageName, pageSize: number): void {
    const itemsPerPage = this.getItemsPerPageObject(pageName);
    if (itemsPerPage) {
      itemsPerPage.pageSize = pageSize;
      this.updatePageSize(itemsPerPage);
    } else {
      this.insertPageSize(pageName, pageSize);
    }
  }

  insertPageSize(pageName: PageName, pageSize: number): void {
    const itemsPerPageList = this.getItemsPerPageList();
    const itemsPerPage = new ItemsPerPage();
    itemsPerPage.pageSize = pageSize;
    itemsPerPage.pageName = pageName;
    itemsPerPageList.push(itemsPerPage);
    localStorage.setItem('ItemsPerPage', JSON.stringify(itemsPerPageList));
  }

  updatePageSize(itemsPerPage: ItemsPerPage): void {
    const itemsPerPageList = this.getItemsPerPageList();
    itemsPerPageList.filter((x) => x.pageName === itemsPerPage.pageName)[0].pageSize = itemsPerPage.pageSize;
    localStorage.setItem('ItemsPerPage', JSON.stringify(itemsPerPageList));
  }

  getItemsPerPageObject(pageName: PageName): ItemsPerPage | undefined {
    const itemsPerPageList = this.getItemsPerPageList();
    return itemsPerPageList.filter((x) => x.pageName === pageName)[0];
  }

  getItemsPerPageList(): ItemsPerPage[] {
    return JSON.parse(localStorage.getItem('ItemsPerPage') || '[]') as ItemsPerPage[];
  }
}
