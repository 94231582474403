import { Component, Renderer2 } from '@angular/core';
import { takeUntil } from 'rxjs';
import { BaseComponent } from './shared/components/base.component';
import { ISidebar, SidebarService } from './shared/services/sidebar.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent {
  sidebar!: ISidebar;
  constructor(private readonly sidebarService: SidebarService, private readonly renderer: Renderer2) {
    super();
  }

  ngOnInit(): void {
    this.setupSidebar();
  }

  private setupSidebar() {
    this.sidebarService
      .getSidebar()
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => {
        this.sidebar = res;
      });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.renderer.addClass(document.body, 'default-transition');
    }, 1500);
  }
}
