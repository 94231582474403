export const CONSTANTS = {
  defaultLanguage: 'nl-NL',
  yearFormat: 'YYYY',
  dateFormat: 'DD/MM/YYYY',
  dateTimeFormat: 'DD/MM/YYYY h:mm A',
  loadingSpinnerDelay: 250,
  meterTemplateFileName: 'Empty Meter Template.xlsx',
  propertyTemplateFileName: 'Empty Property Template.xlsx',
  genericErrorMessage: 'Something went wrong. Please contact your C&W administrator.',
  conflictErrorMessage: "That didn't work. Please retry.",
  dataLoadingMessage: 'Hang on, we are getting your data...',
  dataEnterMessage: "Click the 'Add Entry' button to get started.",
  emptyDataMessage: "You don't seem to have any data yet.",
  emptyFilteredDataMessage: 'No data matches your current filters.',
  emptySearchResultsMessage: 'No search results found.',
  integerPlaceholderText: 'Enter a positive integer',
  oneDecimalPlaceholderText: 'Enter a number with one decimal place',
  blankItemText: '(Blank)',
  notAvailableText: 'N/A',
  totalNameText: 'Total',
  fixValidations: 'Please fix field validation(s).',
  emailRegex: '^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$',

  pageSize: 50,
  currentYear: new Date().getFullYear()
} as const;
