/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-inferrable-types */
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoadingService } from '../services/loading.service';

@Injectable()
export class LoadingScreenInterceptor implements HttpInterceptor {
  private activeRequests = 0;
  private readonly skipUrls: string[] = [
    'GetClients',
    'getProjectTypes',
    'getClientGroups',
    'getClientSubGroups',
    'getCommissionRequestAudits',
    'getCommissionFile',
    'getClientLinks'
  ];

  constructor(private readonly loadingService: LoadingService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let displayLoadingScreen = true;
    if (this.skipUrls.some((url) => new RegExp(url).test(request.url))) {
      displayLoadingScreen = false;
    }

    if (request.params?.get('skipLoading') === 'true') {
      displayLoadingScreen = false;
      request.params.delete('skipLoading', 'true');
    }

    if (displayLoadingScreen) {
      if (!this.activeRequests) {
        this.loadingService.startLoading();
      }
      this.activeRequests++;

      return next.handle(request).pipe(
        finalize(() => {
          this.activeRequests--;
          if (!this.activeRequests) {
            this.loadingService.stopLoading();
          }
        })
      );
    } else {
      return next.handle(request);
    }
  }
}
