import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {
  @Input() currentPage!: number;
  @Input() pageSize!: number;
  @Input() totalCount!: number;
  @Output() pageSizeChanged = new EventEmitter<number>();
  onPageSizeChange(pageSize: number): void {
    this.pageSize = pageSize;
    this.pageSizeChanged.emit(pageSize);
  }
}
