import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Subscription, takeUntil } from 'rxjs';
import { RequestAudit, RequestChanges } from 'src/app/requests/models/requestAudit';
import { CommissionRequestService } from 'src/app/requests/services/commission-request.service';
import { CommissionRequest } from '../../models/commissionRequest';
import { StorageService } from '../../services/local-storage.service';
import { BaseComponent } from '../base.component';

import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { UserOptions } from 'jspdf-autotable';
import { ClientAdminConfigService } from 'src/app/admin/services/client-admin-config.service';
import { PdfSharedService } from 'src/app/requests/services/pdf-shared.service';
import { CWImageLogo } from '../../constants/cw-logo-base64';

interface jsPDFCustom extends jsPDF {
  autoTable: (options: UserOptions) => void;
}

@Component({
  selector: 'app-pdf-generate',
  templateUrl: './pdf-generate.component.html',
  styleUrls: ['./pdf-generate.component.scss']
})
export class PdfGenerateComponent extends BaseComponent {
  @Input() selectedData!: CommissionRequest;
  requestAudits: RequestAudit[] = [];
  auditChanges: RequestChanges[] = [];
  createdDate!: string;
  projectStartDate!: string;
  projectDueDate!: string;
  dateSubmitedToClient!: string;
  dateApprovedByClient!: string;
  dateFormat = '';
  documentNames = '';
  clickEventsubscription!: Subscription;
  address = '';
  projectDrivers = '';
  recitals = '';
  scopeOfService = '';
  feeStructure = '';
  minimumFee = '';
  rebate = '';
  feeResponsibility = '';
  paymentTerms = '';
  earlyTermination = '';
  reimbursableExpences = '';
  otherSpecialist = '';
  purchaseOrder = '';
  projectName = '';
  savedFields: string[] = [];
  constructor(
    private readonly requestService: CommissionRequestService,
    private readonly datePipe: DatePipe,
    private readonly storageService: StorageService,
    private pdfSharedService: PdfSharedService,
    private readonly clientAdminConfigService: ClientAdminConfigService
  ) {
    super();
    this.pdfSharedService.invokePDFGenarator.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.exportPdf();
    });
  }

  ngOnInit(): void {
    const dateTimeFormat = this.storageService.lastClientSelectorData.clientDateFormat;
    this.dateFormat = this.storageService.lastClientSelectorData.clientDateFormat.split(' ')[0];
    this.createdDate = this.datePipe.transform(this.selectedData?.dateCreated, this.dateFormat)!;
    this.dateSubmitedToClient = this.datePipe.transform(this.selectedData?.dateSubmitToClient, this.dateFormat)!;
    this.dateApprovedByClient = this.datePipe.transform(this.selectedData?.dateSignedOff, this.dateFormat)!;
    this.projectStartDate = this.datePipe.transform(this.selectedData?.projectStartDate, this.dateFormat)!;
    this.projectDueDate = this.datePipe.transform(this.selectedData?.projectDueDate, this.dateFormat)!;

    if (this.selectedData?.projectName) {
      this.projectName = this.selectedData?.projectName.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    if (this.selectedData?.projectDescription) {
      this.projectDrivers = this.selectedData?.projectDescription.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    if (this.selectedData?.recitals) {
      this.recitals = this.selectedData?.recitals.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    if (this.selectedData?.propertyAddress) {
      this.address = this.selectedData?.propertyAddress.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    if (this.selectedData?.scopeOfService) {
      this.scopeOfService = this.selectedData?.scopeOfService.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    if (this.selectedData?.feeStructure) {
      this.feeStructure = this.selectedData?.feeStructure.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    if (this.selectedData?.minimumFee) {
      this.minimumFee = this.selectedData?.minimumFee.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    if (this.selectedData?.rebateOrDiscount) {
      this.rebate = this.selectedData?.rebateOrDiscount.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    if (this.selectedData?.thirdPartyContribution) {
      this.feeResponsibility = this.selectedData?.thirdPartyContribution.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    if (this.selectedData?.paymentTerm) {
      this.paymentTerms = this.selectedData?.paymentTerm.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    if (this.selectedData?.earlyTerminationFee) {
      this.earlyTermination = this.selectedData?.earlyTerminationFee.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    if (this.selectedData?.reimbursableExpenses) {
      this.reimbursableExpences = this.selectedData?.reimbursableExpenses.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    if (this.selectedData?.otherSpecialist) {
      this.otherSpecialist = this.selectedData?.otherSpecialist.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    if (this.selectedData?.purchaseOrderNumber) {
      this.purchaseOrder = this.selectedData?.purchaseOrderNumber.replace(/\n/g, '</br>').replace(/\t/g, ' ');
    }

    this.requestService
      .getAuditData(this.selectedData?.guid)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        this.requestAudits = result;
        this.requestAudits.forEach((requestAudit) => {
          requestAudit.modifiedOnString = this.datePipe.transform(requestAudit.modifiedOn, dateTimeFormat)!;
          requestAudit.changes.forEach((change) => {
            if (change.field === 'Project Start Date') {
              const old = change.oldValue.split('-');
              const oldDate: Date = new Date(+old[0], +old[1] - 1, +old[2]);

              const newArray = change.newValue.split('-');
              const newDate: Date = new Date(+newArray[0], +newArray[1] - 1, +newArray[2]);

              change.oldValue = this.datePipe.transform(oldDate, this.dateFormat)!;
              change.newValue = this.datePipe.transform(newDate, this.dateFormat)!;
            }

            if (
              change.field === 'Project Name' ||
              change.field === 'Fee Structure' ||
              change.field === 'Project Drivers' ||
              change.field === 'Address' ||
              change.field === 'Scope Of Service' ||
              change.field === 'Minimum Fee' ||
              change.field === 'Rebate / Discount' ||
              change.field === 'Fee Responsibility / Third-Party Contribution' ||
              change.field === 'Payment Terms' ||
              change.field === 'Early Termination / Abortive Fees' ||
              change.field === 'Reimbursable Expenses' ||
              change.field === 'Other Specialist' ||
              change.field === 'Recitals' ||
              change.field === 'Invoicing Requirements & Purchase Order' ||
              change.field === 'Comments'
            ) {
              change.newValue = change.newValue.replace(/\n/g, '</br>').replace(/\t/g, ' ');
              change.oldValue = change.oldValue.replace(/\n/g, '</br>').replace(/\t/g, ' ');
            }
            if (change.field === 'Status') {
              change.newValue = change.newValue == 'Declined' ? 'Rejected' : change.newValue;
              change.oldValue = change.oldValue == 'Declined' ? 'Rejected' : change.oldValue;
            }
          });
        });
        this.requestAudits.sort((a, b) => (a.modifiedOn < b.modifiedOn ? -1 : 1));
      });

    this.requestService
      .getUploadFiles(this.selectedData?.guid)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((result) => {
        const names = result.map((s) => s.fileName);
        this.documentNames = names.join();
      });

    this.getSavedFields();
  }

  getSavedFields(): void {
    this.savedFields = this.selectedData?.savedFieldsJson.map((s) => s.fieldName);
  }

  exportPdf(): void {
    const base64Img = CWImageLogo.CWLogo;
    const doc = new jsPDF() as jsPDFCustom;
    doc.autoTable({
      html: '#content',
      didDrawPage: function (data) {
        doc.setFontSize(24);
        doc.setTextColor(40);
        doc.addImage(base64Img, 'JPEG', data.settings.margin.left, 15, 45, 10);
        doc.text('Request for Service', 122, 24);
      },
      margin: { top: 30 },
      theme: 'grid',
      styles: { overflow: 'linebreak' },
      didParseCell: function (data) {
        const tdElement = data.cell.raw as HTMLElement;
        if (tdElement.classList.contains('header')) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.textColor = '#000000';
          data.cell.styles.fillColor = '#d9d9d9';
          data.cell.styles.cellWidth = 60;
        } else {
          data.cell.styles.textColor = '#000000';
        }
      }
    });
    doc.addPage();
    doc.autoTable({
      html: '#table2',
      didDrawPage: function (data) {
        doc.setFontSize(24);
        doc.addImage(base64Img, 'JPEG', data.settings.margin.left, 15, 45, 10);
        doc.text('Request for Service', 122, 24);
      },
      margin: { top: 30 },
      theme: 'grid',
      didParseCell: function (data) {
        const tdElement = data.cell.raw as HTMLElement;
        if (tdElement.classList.contains('header')) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.fillColor = 'gray';
          data.cell.styles.textColor = '#000000';
          data.cell.styles.fillColor = '#d9d9d9';
          data.cell.styles.cellWidth = 60;
        } else {
          data.cell.styles.textColor = '#000000';
        }
      }
    });
    doc.addPage();

    doc.autoTable({
      html: '#table3',
      headStyles: { fillColor: [255, 0, 0] },
      didDrawPage: function (data) {
        doc.setFontSize(15);
        doc.addImage(base64Img, 'JPEG', data.settings.margin.left, 15, 45, 10);
        doc.text('AUDIT LOG', 15, 33);
        doc.setFontSize(24);
        doc.text('Request for Service', 122, 24);
        doc.setLineWidth(0);
      },
      margin: { top: 40 },
      theme: 'grid',
      didParseCell: function (data) {
        const tdElement = data.cell.raw as HTMLElement;
        if (tdElement.classList.contains('header')) {
          data.cell.styles.fontStyle = 'bold';
          data.cell.styles.fillColor = 'gray';
          data.cell.styles.textColor = '#000000';
          data.cell.styles.fillColor = '#d9d9d9';
          // change cell width
          data.cell.styles.cellWidth = 60;
        } else {
          data.cell.styles.textColor = '#000000';
        }
      }
    });

    this.addFooters(doc);
    doc.save(this.selectedData.requestNumber + ' ' + this.datePipe.transform(new Date(), 'dd-MM-yyyy'));
  }

  addFooters = (doc: jsPDF): void => {
    const pageCount = doc.internal.pages;
    const date = this.datePipe.transform(new Date(), this.dateFormat);
    doc.setFontSize(8);
    for (let i = 1; i <= pageCount.length - 1; i++) {
      doc.setPage(i);
      doc.text('Page ' + String(i) + ' of ' + String(pageCount.length - 1), 20, doc.internal.pageSize.height - 8, {});
      doc.text(date!.toString(), doc.internal.pageSize.width - 30, doc.internal.pageSize.height - 8, {});
    }
  };
}
