import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { FunctionUrls } from 'src/app/shared/constants/function-urls.constant';
import { ClientLink } from 'src/app/shared/models/client-link';
import { BaseDataService } from 'src/app/shared/services/base-api-service';

@Injectable({
  providedIn: 'root'
})
export class ClientLinkService extends BaseDataService {
  private readonly clientLinkDataMap = new Map<string, ClientLink[]>();
  getData(clientGuid: string): Observable<ClientLink[]> {
    if (this.clientLinkDataMap.has(clientGuid)) {
      return of(this.clientLinkDataMap.get(clientGuid)!);
    }

    return new Observable((subscriber) => {
      this.getMappedList<ClientLink>(`${FunctionUrls.dataApi.getClientLinks}/${clientGuid}`, ClientLink).subscribe({
        next: (result) => {
          this.clientLinkDataMap.set(clientGuid, result);
          subscriber.next(result);
        },
        error: (error) => {
          subscriber.error(error);
        }
      });
    });
  }

  create(data: ClientLink): Observable<string> {
    this.clientLinkDataMap.delete(data.clientGuid);
    return this.post<string>(FunctionUrls.dataApi.createClientLink, data);
  }

  delete(data: ClientLink): Observable<string> {
    this.clientLinkDataMap.delete(data.clientGuid);
    return this.post<string>(FunctionUrls.dataApi.deleteClientLink, data.clientLinkId);
  }
}
