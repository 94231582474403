import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ClientSelectorData } from '../models/client';
import { User } from '../models/user.model';
import { StorageService } from '../services/local-storage.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate {
  constructor(public router: Router, private readonly authService: UserService, private readonly storageService: StorageService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const allowedRoles = route.data.allowedRoles;

    return new Observable((subscriber) => {
      this.authService.currentUser$.subscribe(
        (user: User) => {
          const authorized = user.hasAnyRole(allowedRoles, this.storageService.lastClientSelectorData.clientGuid);

          subscriber.next(authorized);
          if (!authorized) {
            localStorage.setItem('ClientDetails', JSON.stringify(JSON.stringify(new ClientSelectorData())));
            this.router.navigate(['/home']);
          }
        },
        () => {
          subscriber.error(false);
          localStorage.setItem('ClientDetails', JSON.stringify(JSON.stringify(new ClientSelectorData())));
          this.router.navigate(['/home']);
        }
      );
    });
  }
}
