import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { environment } from 'src/environments/environment';
import { ClientLinkComponent } from './admin/components/client-link/client-link.component';
import { UserRole } from './shared/constants/roles.constant';
import { RoleGuard } from './shared/guards/role.guard';

const msalAndRoleGuard: unknown[] = environment.isAuthGuardActive ? [MsalGuard, RoleGuard] : [];

const allRoles: UserRole[] = ['Administrator', 'CW Lead', 'CW Delegate', 'External User'];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule)
  },
  {
    path: 'requests',
    canActivate: msalAndRoleGuard,
    loadChildren: () => import('./requests/requests.module').then((m) => m.RequestsModule),
    data: { allowedRoles: allRoles }
  },
  {
    path: 'notlinks',
    canActivate: msalAndRoleGuard,
    component: ClientLinkComponent,
    data: { allowedRoles: allRoles }
  },
  {
    path: 'admin',
    canActivate: msalAndRoleGuard,
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    data: { allowedRoles: ['Administrator'] }
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
