export class RequestAudit {
  modifiedBy!: string;
  modifiedOn!: Date;
  modifiedOnString!: string;
  changes!: RequestChanges[];

  constructor(source: Partial<RequestAudit>) {
    Object.assign(this, source);
  }
}

export class RequestChanges {
  field!: string;
  oldValue!: string;
  newValue!: string;

  constructor(source: Partial<RequestChanges>) {
    Object.assign(this, source);
  }
}
