import { RequestStatus } from '../constants/request-status.constants';
import { RequestFields } from './request-field';

export class CommissionRequest {
  guid!: string;
  statusName!: RequestStatus;
  projectTypeDescription!: string;
  clientGroupDescription!: string;
  clientSubGroupDescription!: string;
  clientGuid!: string;
  requestNumber!: string;
  haveMultipleProperties!: boolean;
  projectTypeId!: number;
  projectDescription!: string;
  propertyId!: number;
  strategy!: string;
  localBroker!: string;
  propertyAddress!: string;
  projectStartDate!: string;
  clientGroupId!: number;
  clientSubGroupId!: number;
  isConfidential!: boolean;
  estimatedFeeAmount!: number;
  feeStructure!: string;
  revisedEstimatedFeeAmount!: number;
  isApprovalAttained!: boolean;
  approvalAttained!: string;
  dateSignedOff!: Date;
  dateSignedOffString!: string;
  purchaseOrderNumber!: string;
  cwSystemProjectId!: number;
  requesterId!: number;
  requesterName!: string;
  requesterWorkEmail!: string;
  requesterNameEmail!: string;
  cwDelegateId!: number;
  cwDelegateName!: string;
  ceDelegateWorkEmail!: string;
  cwDelegateNameEmail!: string;
  reviewerComment!: string;
  cwLeadId!: string;
  cwLeadName!: string;
  cwLeadWorkEmail!: string;
  cwLeadNameEmail!: string;
  requestStatusId!: number;
  modifiedBy!: string;
  modifiedOn!: Date;
  modifiedOnString!: string;
  createdDate!: Date;
  createdDateString!: string;
  projectName!: string;
  countryId!: number;
  countryName!: string;
  stateId!: number;
  stateName!: string;
  city!: string;
  propertyTypeId!: number;
  propertyTypeName!: string;
  unitOfMeasurementId!: number;
  unitName!: string;
  currentArea!: number;
  targetArea!: number;
  projectDueDate!: string;
  projectDueDateOnString!: string;
  scopeOfService!: string;
  recitals!: string;
  currencyId!: number;
  currencyType!: string;
  minimumFee!: string;
  rebateOrDiscount!: string;
  thirdPartyContribution!: string;
  paymentTerm!: string;
  earlyTerminationFee!: string;
  reimbursableExpenses!: string;
  otherSpecialist!: string;
  cwContractingEntity!: string;
  clientContractingEntity!: string;
  dateCreated!: string;
  dateSubmitToClient!: Date;
  savedFieldsJson!: RequestFields[];
  constructor(source: Partial<CommissionRequest>) {
    this.cwLeadNameEmail = source.cwLeadId ? `${source.cwLeadName} (${source.cwLeadWorkEmail})` : '';
    if (source.requesterName != null && source.requesterWorkEmail != null) {
      this.requesterNameEmail = `${source.requesterName} (${source.requesterWorkEmail})`;
    }
    this.approvalAttained = source.isApprovalAttained ? 'Yes' : 'No';
    Object.assign(this, source);
  }
}
