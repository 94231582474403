/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import MODAL_DEFAULT_CONFIG from '../../constants/modal-default.constant';
import { ConfirmReturn } from '../../models/confirm-return';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent {
  modalRef!: BsModalRef;
  modalTitle = '';
  modalContent = '';
  okButtonText = '';
  cancelButtonText = '';
  modalId = 0;
  commentBox? = false;
  comment = '';
  isButtonsDisabled = false;

  @ViewChild('confTemplate', { static: true }) template!: TemplateRef<any>;
  @Output() confirmed: EventEmitter<ConfirmReturn> = new EventEmitter();

  constructor(private readonly modalService: BsModalService, private readonly notify: NotificationService) {}

  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  show(title: string, content: string, okText: string, cancelText: string, modalConfig?: any, commentBoxVisible?: boolean): void {
    this.isButtonsDisabled = false;
    this.modalTitle = title;
    this.modalContent = content;
    this.okButtonText = okText;
    this.cancelButtonText = cancelText;
    this.comment = '';
    this.commentBox = commentBoxVisible;

    if (modalConfig) {
      this.modalId = modalConfig.id;
      this.modalRef = this.modalService.show(this.template, modalConfig);
    } else {
      this.modalRef = this.modalService.show(this.template, MODAL_DEFAULT_CONFIG);
    }
  }

  onConfirm(): void {
    this.isButtonsDisabled = true;
    if (this.commentBox) {
      this.validateComment();
    } else {
      this.confirmed.emit({ confirm: true });
      if (this.modalId > 0) {
        this.modalService.hide(this.modalId);
      } else {
        this.modalService.hide();
      }
    }
  }

  validateComment(): void {
    this.comment = this.comment.trim();

    if (this.comment == '') {
      this.notify.hint(`Please enter a comment.`);
      this.isButtonsDisabled = false;
      return;
    }
    this.confirmed.emit({ confirm: true, comment: this.comment });
    this.modalService.hide(this.modalId);
  }

  onCancel(): void {
    this.confirmed.emit({ confirm: false });
    if (this.modalId > 0) {
      this.modalService.hide(this.modalId);
    } else {
      this.modalService.hide();
    }
  }
}
