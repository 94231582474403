export class CommissionFile {
  fileGuid!: string;
  guid!: string;
  fileName!: string;
  uploadOn!: Date;
  uploadedUserId!: number;
  fileSize!: number;
  fileSizeString!: string;
  file!: File;

  constructor(source: Partial<CommissionFile>) {
    Object.assign(this, source);
  }
}
