import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { delay, Observable, of } from 'rxjs';
import clientGroups from 'src/assets/mockdata/clientGroups.json';
import clientSubGroups from 'src/assets/mockdata/clientSubGroups.json';
import commissionRequests from 'src/assets/mockdata/CommissionRequests.json';
import currentUser from 'src/assets/mockdata/currentUser.json';
import projectTypes from 'src/assets/mockdata/projectTypes.json';
import requestStatus from 'src/assets/mockdata/requestStatus.json';
import users from 'src/assets/mockdata/users.json';

@Injectable()
export class MockHttpRequestInterceptor implements HttpInterceptor {
  private readonly delayTime = 200;

  constructor(private readonly injector: Injector) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.match('graph')) {
      return of(new HttpResponse({ status: 200, body: null }));
    }

    if (request.url.match('getLoggedInUser')) {
      return of(new HttpResponse({ status: 200, body: currentUser })).pipe(delay(this.delayTime));
    }
    if (request.url.match('getCommissionRequests')) {
      return of(new HttpResponse({ status: 200, body: commissionRequests })).pipe(delay(this.delayTime));
    }
    if (request.url.match('getRequestStatus')) {
      return of(new HttpResponse({ status: 200, body: requestStatus })).pipe(delay(this.delayTime));
    }

    if (request.url.match('createProjectType')) {
      return of(new HttpResponse({ status: 200, body: null })).pipe(delay(this.delayTime));
    }
    if (request.url.match('getProjectType')) {
      return of(new HttpResponse({ status: 200, body: projectTypes })).pipe(delay(this.delayTime));
    }
    if (request.url.match('createClientGroup')) {
      return of(new HttpResponse({ status: 200, body: null })).pipe(delay(this.delayTime));
    }
    if (request.url.match('getClientGroup')) {
      return of(new HttpResponse({ status: 200, body: clientGroups })!).pipe(delay(this.delayTime));
    }
    if (request.url.match('createClientSubGroup')) {
      return of(new HttpResponse({ status: 200, body: null })).pipe(delay(this.delayTime));
    }
    if (request.url.match('getClientSubGroups')) {
      return of(new HttpResponse({ status: 200, body: clientSubGroups })).pipe(delay(this.delayTime));
    }

    if (request.url.match('getUsers')) {
      return of(new HttpResponse({ status: 200, body: users })).pipe(delay(this.delayTime));
    }

    return next.handle(request);
  }
}
